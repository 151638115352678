import axios from 'axios'
import { Field, FieldGroup, FieldToggler, FormContext, FormContextProvider, FormInContext } from 'Components/form'
import { Datepicker, Dropdown, LanguageDropdown, Switch, TextInput } from 'Components/input'
import { Button, ModalActionsFooter } from 'Components/readonly'
import useGetData from 'Hooks/useGetData'
import { GrowlContext } from 'Context'
import { format, parse } from 'date-fns'
import _ from 'lodash'
import CountryDropdown from 'Pages/sds/components/countryDropdown'
import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'

const OrganisationDialogForm = ({ organisation, markets, isLoading, onClose, onSubmit }) => {
  const intl = useIntl()
  const { getFieldValue, setFieldValue } = useContext(FormContext)

  const paymentTypes = [
    { label: intl.formatMessage({ id: 'organisation.kind_piece' }), value: 'piece' },
    { label: intl.formatMessage({ id: 'organisation.kind_subscription' }), value: 'subscription' },
    { label: intl.formatMessage({ id: 'organisation.kind_maintenance' }), value: 'maintenance' }
  ]

  const ModuleSwitched = (module, e) => {
    if (e.target.value && !getFieldValue(module + '_start_date')) {
      setFieldValue(module + '_start_date', new Date())
    }
  }

  const SwitchMarket = (market, e) => {
    setFieldValue('enable_market_' + market, e.target.value)
  }

  return (
    <FormInContext onSubmit={onSubmit}>
      <FieldGroup>
        <Field id="name" label={intl.formatMessage({ id: 'common.name' })} Component={TextInput} required={true}/>
        <Field id="sortorder" label={intl.formatMessage({ id: 'common.sortorder' })} Component={TextInput}
               keyfilter="pnum" type="number"/>
        <Field id="tool3_id" label="ID" Component={TextInput} disabled={organisation.id} required={true}/>
        <Field id="address.Org_No" label={intl.formatMessage({ id: 'organisation.org-id' })} Component={TextInput}/>
        <Field id="address.Att" label={intl.formatMessage({ id: 'organisation.att' })} Component={TextInput}/>
        <Field id="address.Address1" label={intl.formatMessage({ id: 'common.address' })} Component={TextInput}/>
        <Field id="address.Address2" Component={TextInput}/>
        <Field id="address.Address3" Component={TextInput}/>
        <Field id="address.Zip" label={intl.formatMessage({ id: 'common.zip' })} Component={TextInput}/>
        <Field id="address.Town" label={intl.formatMessage({ id: 'common.town' })} Component={TextInput}/>
        <Field id="address.Country" label={intl.formatMessage({ id: 'common.country' })} Component={CountryDropdown}/>
        <Field
          id="address.DefaultLang"
          label={intl.formatMessage({ id: 'common.language' })}
          Component={LanguageDropdown}
          required={true}
        />
        <Field
          id="enable_chemical_list"
          Component={Switch}
          label={intl.formatMessage({ id: 'common.activate_service_chemicallist' })}
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
          onChange={(e) => ModuleSwitched('chemical_list', e)}
        />
        {markets &&
          getFieldValue('enable_chemical_list') &&
          markets.map((market) => (
            <Field
              id={'enable_market_' + market}
              key={market}
              Component={Switch}
              label={
                intl.formatMessage({ id: 'common.activate_market_' + market }) + ' (' + intl.formatMessage({ id: 'chemicals.module' }) + ')'
              }
              onLabel={intl.formatMessage({ id: 'common.yes' })}
              offLabel={intl.formatMessage({ id: 'common.no' })}
              disabled={!getFieldValue('enable_chemical_list')}
              required={true}
              onChange={(e) => SwitchMarket(market, e)}
            />
          ))}
        <FieldToggler show="enable_chemical_list">
          <Field id="chemical_list_start_date" label={intl.formatMessage({ id: 'common.start_date' })}
                 Component={Datepicker}/>
          <Field id="chemical_list_end_date" label={intl.formatMessage({ id: 'common.end_date' })}
                 Component={Datepicker}/>
        </FieldToggler>
        <Field
          id="enable_sds"
          label={intl.formatMessage({ id: 'common.activate_service_sds' })}
          Component={Switch}
          onLabel={intl.formatMessage({ id: 'common.yes' })}
          offLabel={intl.formatMessage({ id: 'common.no' })}
          onChange={(e) => ModuleSwitched('sds', e)}
        />
        <FieldToggler show="enable_sds">
          <Field id="sds_start_date" label={intl.formatMessage({ id: 'common.start_date' })} Component={Datepicker}/>
          <Field id="sds_end_date" label={intl.formatMessage({ id: 'common.end_date' })} Component={Datepicker}/>
          <Field
            id="kind"
            label={intl.formatMessage({ id: 'common.customertype' })}
            Component={Dropdown}
            options={paymentTypes}
            required="enable_sds"
          />
          <Field
            id="weblink"
            label={intl.formatMessage({ id: 'admin.weblink' })}
            Component={Switch}
            onLabel={intl.formatMessage({ id: 'common.yes' })}
            offLabel={intl.formatMessage({ id: 'common.no' })}
          />
        </FieldToggler>
      </FieldGroup>
      <ModalActionsFooter>
        <Button label={intl.formatMessage({ id: 'common.cancel' })} onClick={onClose}/>
        <Button primary submit icon="pi pi-save" label={intl.formatMessage({ id: 'common.save' })}
                isLoading={isLoading}/>
      </ModalActionsFooter>
    </FormInContext>
  )
}

const OrganisationDialog = ({ modalController, onSuccess, ...props }) => {
  const organisation = props.organisation || {}

  const [isLoading, setIsLoading] = useState(false)
  const { displaySuccess, displayError } = useContext(GrowlContext)
  const [, markets] = useGetData('/rapi/admin/markets', ['SE', 'NO', 'DK'])

  const createOrganisation = async (formData) => {
    setIsLoading(true)
    try {
      const response = await axios.post('/rapi/organisation', formData)
      onSuccess(response.data.result)
      displaySuccess(response.data.message, response.data.result.name)
      reloadPage()
    } catch (error) {
      setIsLoading(false)
      displayError(error)
    }
  }

  const updateOrganisation = async (organisation, formData) => {
    setIsLoading(true)
    try {
      const response = await axios.put('/rapi/organisation/' + organisation.id, formData)
      onSuccess(response.data.result, true)
      displaySuccess(response.data.message, response.data.result.name)
      reloadPage()
    } catch (error) {
      setIsLoading(false)
      displayError(error)
    }
  }

  const reloadPage = () => {
    setTimeout(() => {
      setIsLoading(false)
      window.location.reload(false)
      modalController.close()
    }, 2500)
  }

  const onSubmit = async (formData) => {
    const sendData = _.cloneDeep(formData)
    sendData.sds_start_date = formData.sds_start_date ? format(formData.sds_start_date, 'YYYY-MM-DD') : null
    sendData.sds_end_date = formData.sds_end_date ? format(formData.sds_end_date, 'YYYY-MM-DD') : null
    sendData.chemical_list_start_date = formData.chemical_list_start_date ? format(formData.chemical_list_start_date, 'YYYY-MM-DD') : null
    sendData.chemical_list_end_date = formData.chemical_list_end_date ? format(formData.chemical_list_end_date, 'YYYY-MM-DD') : null
    sendData.weblink = formData.weblink ? 2 : 0

    if (organisation.id) {
      updateOrganisation(organisation, sendData)
    } else {
      createOrganisation(sendData)
    }
  }

  const incomingOrganisationData = { ...organisation }

  const getInitialValue = (valueName, defaultValue) => _.get(incomingOrganisationData, valueName, defaultValue)
  const initialChemicalStartDate = getInitialValue('chemical_list_start_date', null)
    ? parse(getInitialValue('chemical_list_start_date', null))
    : null
  const initialChemicalEndDate = getInitialValue('chemical_list_end_date', null)
    ? parse(getInitialValue('chemical_list_end_date', null))
    : null
  const initialSdsStartDate = getInitialValue('sds_start_date', null) ? parse(getInitialValue('sds_start_date', null)) : null
  const initialSdsEndDate = getInitialValue('sds_end_date', null) ? parse(getInitialValue('sds_end_date', null)) : null

  const initialFormData = {
    name: getInitialValue('name', ''),
    sortorder: getInitialValue('sortorder', ''),
    tool3_id: getInitialValue('tool3_id', ''),
    address: {
      Org_No: getInitialValue('address.Org_No', ''),
      Att: getInitialValue('address.Att', ''),
      Address1: getInitialValue('address.Address1', ''),
      Address2: getInitialValue('address.Address2', ''),
      Address3: getInitialValue('address.Address3', ''),
      Zip: getInitialValue('address.Zip', ''),
      Town: getInitialValue('address.Town', ''),
      Country: getInitialValue('address.Country', 'SE'),
      DefaultLang: getInitialValue('address.DefaultLang', 'SWE')
    },
    enable_chemical_list: getInitialValue('enable_chemical_list', false),
    chemical_list_start_date: initialChemicalStartDate,
    chemical_list_end_date: initialChemicalEndDate,
    enable_sds: getInitialValue('enable_sds', false),
    sds_start_date: initialSdsStartDate,
    sds_end_date: initialSdsEndDate,
    kind: getInitialValue('kind', 'piece'),
    weblink: getInitialValue('weblink', false)
  }

  if (markets) {
    markets.map(
      (market) =>
        (initialFormData['enable_market_' + market] = incomingOrganisationData.organisationMarkets
          ? incomingOrganisationData.organisationMarkets.includes(market)
          : false)
    )
  }

  return (
    <FormContextProvider initialFormData={initialFormData}>
      <OrganisationDialogForm
        organisation={organisation}
        markets={markets}
        isLoading={isLoading}
        onClose={modalController.close}
        onSubmit={onSubmit}
      />
    </FormContextProvider>
  )
}

export default OrganisationDialog
